<template>
  <div>
      <!-- Manage页面 -->
      <div class="commonInputTopBox">
          <infoHeader :content_name="'停车记录详情'" />
      </div>

      <el-row :gutter="20">
          <!-- 任务说明 -->
          <el-col :span="17">
              <el-descriptions
                  title="停车任务信息"
                  direction="vertical"
                  style="margin-bottom: 20px;"
                  :column="2"
                  v-for="(task,index) in taskList" :key="index"
                  border
              >
                  <el-descriptions-item label="停车任务ID">{{
                      infoData.id
                  }}</el-descriptions-item>
                  <el-descriptions-item label="任务类型">{{
                      task.task_type
                  }}</el-descriptions-item>
                  <el-descriptions-item label="任务是否允许执行">{{
                      task.allow_execute
                  }}</el-descriptions-item>
                  <el-descriptions-item label="任务状态">{{
                      task.task_status
                  }}</el-descriptions-item>
                  <el-descriptions-item label="任务是否接收">{{
                      task.revc_status
                  }}</el-descriptions-item>
                  <el-descriptions-item label="车库名称">{{
                      tableData.garage.info_name
                  }}</el-descriptions-item>
                  <el-descriptions-item label="停车方式">{{
                      task.execution_mode
                  }}</el-descriptions-item>
                  <el-descriptions-item label="车牌信息">{{
                      task.license_plate
                  }}</el-descriptions-item>
                  <el-descriptions-item label="车辆状态">{{
                      tableData.parkstatus
                  }}</el-descriptions-item>
                  <el-descriptions-item label="停车时长">{{
                      tableData.parktime_count
                  }}</el-descriptions-item>
                  <el-descriptions-item label="开始停车时间">{{
                      task.create_time
                  }}</el-descriptions-item>
                  <el-descriptions-item label="结束停车时间">{{
                      tableData.outpark_overtime
                  }}</el-descriptions-item>
                  
              </el-descriptions>
              <el-divider></el-divider>
          </el-col>
          <el-col :span="7">
              <div class="parkinginfo-buttonwrap">
                  <div class="parkinginfo-buttongroup">
                      <p class="parkinginfo-title">修改任务状态</p>
                      <el-select
                          v-model="tableData.park_status_code"
                          placeholder="请选择"
                          style="width: 360px"
                      >
                          <el-option
                              v-for="item in options"
                              :key="item.status"
                              :label="item.label"
                              :value="item.status"
                          >
                          </el-option>
                      </el-select>
                      <el-button type="primary" style="margin-left: 20px"
                          >提交</el-button
                      >
                  </div>
                  <div class="parkinginfo-buttongroup">
                      <p class="parkinginfo-title">停车方式</p>
                      <el-button type="primary" class="parkinginfo-button"
                          >手动停车</el-button
                      >
                      <el-button type="success" class="parkinginfo-button"
                          >自动停车</el-button
                      >
                  </div>
              </div>
          </el-col>
      </el-row>
  </div>
</template>

<script>
import { parkinglog_get } from '@/api/partner/garageManage/parkingRecordManage/parklog.js';

export default {
  name: 'start',
  data() {
      return {
          infoData: this.$route.query,
          activeName: 'first',
          status: 2,
          options: [
              {
                  status: 0,
                  label: '正在停车中'
              },
              {
                  status: 3,
                  label: '请前往指定出口取车'
              },
              {
                  status: 4,
                  label: '已结束'
              }
          ],
          tableData: {
              car: {},
              garage: {}
          },
          fromData: {},
          taskList:[]
      };
  },
  components: {},
  created() {
      this.getParkingRecordInfo();
  },
  methods: {
      toMaterial(item) {
          console.log('itemitemitemitem', item);
          // this.$router.push({name:"product_material", query:{data: item}})
          this.$router.push({ name: 'product_material', query: item });
      },
      getParkingRecordInfo() {
        parkinglog_get({ park_id: this.infoData.id }).then((res) => {
              if (res.code == 200) {
                  this.tableData = res.data;
                  this.taskList = res.data.task;
                  console.log(this.taskList,11111111111);
              } else {
                  this.$message.info(res.msg);
              }
          });
      },
      handleEdit(item) {
          item.id;
      }
  }
};
</script>

<style lang="scss" scoped>
.parkinginfo-buttonwrap {
  margin-top: -6px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .parkinginfo-title {
      font-size: 14px;
      font-weight: 600;
      color: #2c3e50;
  }

  .parkinginfo-buttongroup {
      margin-bottom: 10px;
  }

  .parkinginfo-button {
      width: 220px;
  }
}
</style>
