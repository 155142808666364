import request from '@/utils/request'


//二级-停车记录-更新
export function parkinglog_update(data) {
    return request({
        url: '/platform_api/partner_api/parking_record/update',
        method: 'post',
        data: data
    })
}
// 二级-停车记录查询
export function parkinglog_query(data) {
    return request({
        url: '/platform_api/partner_api/park_manage/query',
        method: 'post',
        data: data
    })
}
// 二级-停车记录查询
export function parkinglog_get(data) {
    return request({
        url: '/platform_api/partner_api/park_manage/info',
        method: 'post',
        data: data
    })
}